export default defineNuxtPlugin((nuxtApp) => {
  /* if (import.meta.client) {
    const externalScript = document.createElement("script");
    externalScript.type = "text/javascript";
    externalScript.src =
      "https://cdn.consentmanager.net/delivery/autoblocking/1fdbf80b611c6.js";
    externalScript.setAttribute("data-cmp-ab", "1");
    externalScript.setAttribute(
      "data-cmp-host",
      "c.delivery.consentmanager.net",
    );
    externalScript.setAttribute("data-cmp-cdn", "cdn.consentmanager.net");
    externalScript.setAttribute("data-cmp-codesrc", "0");
    document.head.appendChild(externalScript);
  } */
});
