import { default as datenschutz8Idu2tRmArMeta } from "/app/pages/datenschutz.vue?macro=true";
import { default as impressumLDkFRkkSAmMeta } from "/app/pages/impressum.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as merklistesT0U2whVDoMeta } from "/app/pages/merkliste.vue?macro=true";
import { default as speisekarteASV10DwIk0Meta } from "/app/pages/speisekarte.vue?macro=true";
export default [
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/app/pages/datenschutz.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/app/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "merkliste",
    path: "/merkliste",
    component: () => import("/app/pages/merkliste.vue")
  },
  {
    name: "speisekarte",
    path: "/speisekarte",
    component: () => import("/app/pages/speisekarte.vue")
  }
]